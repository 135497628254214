





















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LogoutModal extends Vue {

  async logout() {
    await Promise.all([
      await this.$store.commit('socketStore/setUserId', ''),
      await this.$store.dispatch('logOut', { historyId: this.getHistoryId }),
    ])
    this.$bvModal.hide('logout-modal');
    await this.$router.push('/login');
  }

  get getHistoryId() {
    return this.$store.getters["historyId"];
  }

  cancel() {
    this.$bvModal.hide('logout-modal');
  }
}
