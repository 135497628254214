





































































































import {Component, Vue} from "vue-property-decorator";

@Component
export default class CostModal extends Vue {
  isLoading = false;
  sampleCount = 0;
  questionCount = 0;
  cost = 0;

  sampleCountCheck = false;
  questionCountCheck = false;

  warningMsg1 = '';
  warningMsg2 = '';

  countCheck() {
    if (this.sampleCount < 100 || this.sampleCount > 2500) {
      this.warningMsg1 = '샘플 수는 100 ~ 2500 이하로 입력해주세요.';
      this.sampleCountCheck = false;
    } else {
      this.warningMsg1 = '';
      this.sampleCountCheck = true;
    }

    if (this.questionCount > 100 || this.questionCount < 1) {
      this.warningMsg2 = '문항 수는 1 ~ 100 개 사이로 입력해주세요.';
      this.questionCountCheck = false;
    } else {
      this.warningMsg2 = '';
      this.questionCountCheck = true;
    }
  }

  reset() {
    this.cost = 0;
    this.sampleCount = 0;
    this.questionCount = 0;
    this.sampleCountCheck = false;
    this.questionCountCheck = false;
    this.warningMsg1 = '';
    this.warningMsg2 = '';
  }

  async calculation() {
    try {
      this.isLoading = true;

      if (this.sampleCountCheck && this.questionCountCheck) {
        const { data } = await this.axios.get(`/project/cost/calculation/${this.questionCount}/${this.sampleCount}`)
        this.cost = data;
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e)
    }
  }
}
