























































import {Component, Vue} from "vue-property-decorator";
import MessageIcon from "@/components/icons/common/MessageIcon.vue";

type STATUS = {
  STATUS: string;
  SIMPLE_SURVEY_TYPE: number;
  LINK_STATUS: number;
}

@Component({
  components: {
    MessageIcon
  }
})
export default class MessageNotification extends Vue {
  active = false;

  async mounted() {
    await this.init();
  }

  async init() {
    await this.$store.dispatch("socketStore/linkStatusCheck", {
      userId: this.userId,
    });
  }

  allCheck() {
    this.$store.dispatch('socketStore/readMessageAll', this.userId)
  }

  async readMessage(SNUM: number) {
    try {
      await this.$store.dispatch('socketStore/unisurveyAlertUpdate', {
        userId: this.userId,
        SNUM,
        ALERT_CHECK: true
      });
    } catch (e) {
      console.log(e);
    }
  }

  get questionNotification() {
    return this.$store.getters["socketStore/questionNotification"] || [];
  }

  get alertCount() {
    return this.questionNotification.length || 0;
  }

  get userId() {
    return this.$store.getters['userId'];
  }

  alertBoxClose() {
    this.active = false;
  }
}

